import Head from "next/head"
import {
  FAQ,
  LandingBlogPreview,
  LandingFeaturedProviders,
  LandingHeroIllustration,
  LandingHeroSearchBar,
  LandingHowItWorks,
  LandingReviewsPreview,
} from "views"
import styled from "styled-components"
import media from "utils/mediaQueryTemplates"

const LandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
`

const LandingHeroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // flex-direction: row;
  flex: 1;
  min-height: 700px;
  height: 80vh;
  max-height: 1000px;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;

  ${media.tablet`
    flex-direction: row;
  `}
`
const LandingHeroTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 0 10px;
  padding-top: 100px;

  & h1 {
    font-family: "Sofia Pro Black";
    position: relative;
    z-index: 1;
    max-width: var(--mobile-width);
    font-size: 45px;
    margin: 0 auto;
    margin-bottom: 40px;
  }

  & p {
    position: relative;
    z-index: 1;
    max-width: var(--mobile-width);
    font-size: 28px;
    line-height: 1.95rem;
    margin: 20px auto 0;
  }

  @media screen and (min-width: 400px) {
    & h1 {
      font-family: "Sofia Pro Black";
      position: relative;
      z-index: 1;
      max-width: var(--mobile-width);
      font-size: 55px;
      margin: 0 auto;
      margin-bottom: 40px;
    }

    & p {
      font-size: 28px;
      margin-right: auto;
      margin-left: 0;
    }
  }

  ${media.small`
    padding: 0 var(--page-side-padding); 
 
    & h1 {
      font-size: 55px;
      margin-top: 80px;     
      margin-bottom: 0px; 
    }

    & p {
      margin-top: 1.5rem;
      font-size: 30px;
      line-height: 40px;      
    }    
  `}

  ${media.medium`
    padding: 0 calc(var(--page-side-padding) * 2);  
  `}

  ${media.tablet`
    padding-left: calc(var(--page-side-padding) * 2);

    & h1 {
      max-width: 100%;
    }

    & p {
      max-width: 100%;
    }    
  `}

  ${media.desktop`
    & h1 {
      font-size: 65px;
      margin-top: 3rem;
      margin-top: 80px;
    }

    & p {
      margin-top: 1.5rem;
      font-size: 30px;
      line-height: 40px;
    }
  `}
`
const FlexDiv = styled.div`
  flex: 1;
`
const LandingHeroText = () => (
  <LandingHeroTextWrapper>
    <h1>{`Where education professionals source their development.`}</h1>
    {/* <p>{`See real reviews for the best professional development experiences around. Find events, rate providers, and quantify the quality of pd resources.`}</p> */}
    <p>{`Find events, rate providers, and quantify the quality of pd resources.`}</p>
  </LandingHeroTextWrapper>
)

const LandingHero: React.FC = () => {
  return (
    <LandingHeroWrapper>
      <LandingHeroText />
      <FlexDiv />
      <LandingHeroIllustration />
      <LandingHeroSearchBar />
    </LandingHeroWrapper>
  )
}

export default function Home() {
  return (
    <>
      <Head>
        <title>Velup.io</title>
        <link rel="icon" href="/assets/logos/logo_150x50.png" />
      </Head>
      <LandingWrapper>
        <LandingHero />
        <LandingHowItWorks />
        <LandingReviewsPreview />
        <LandingBlogPreview />
        {/* <LandingFeaturedProviders /> */}
        <FAQ />
      </LandingWrapper>
    </>
  )
}
